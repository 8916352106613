.stream-planner {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
}

.planner-form {
    margin-right: 20px;
    margin-left: 50px;
    flex: 1; 
    max-height: 80vh;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.planner-form::-webkit-scrollbar{
    display: none; /* chrome safari opera*/
}

.form-group {
    margin-bottom: 10px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.planned-streams {
    max-height: 80vh;
    overflow-y: auto;
    flex: 2; 
    margin-left: 20px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.planned-streams::-webkit-scrollbar{
    display: none; /* chrome safari opera*/
}

.styled-table {
    border-collapse: collapse;
    /* margin: 25px 0; */
    font-size: 0.9em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border: 2px solid black;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
    border: 1px solid black; 
}

.styled-table thead tr {
    background-color: grey;
    color: black;
    text-align: left;
    border-bottom: 2px solid black;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
    background-color: white;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid black;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: black;
}

.styled-table tbody tr.selected-row {
    background-color: #cce5ff;
}

.delete-button2 {
    margin-top: 5px;
    padding: 5px;
    background-color: #dc3545;
    color: #fff;
    border: none;
    cursor: pointer;
}

.delete-button2:hover {
    background-color: #c82333;
}