.card {
  min-width: 200px;
  height: 400px;
  border: 2px solid transparent;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  place-self: center;    
  box-sizing: border-box;
  --bs-card-spacer-y: 0.25rem /*lower vertical spacing*/ 
  --bs-card-spacer-x: 0.25rem; /*lower horizontal spacing*/
  --bs-card-cap-padding-x: 0.25rem;
  --bs-card-cap-padding-y: 0.25rem;
}

.card-body{
  overflow-y: auto;
}

.card-play{
  width: 30%;
  height: 400px;
  border: 1px solid #2a00fe;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;    
  place-self: center;    
  box-sizing: border-box;
}

.card-wrapper.hidden {
  display: none;
}

.card-wrapper.visible {
  display: block;
}

.not-live{
  text-align: center;
  color: #cc1922;
  border: 1px solid;
  border-color: #cc1922;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 4px;
  right: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.live{
  text-align: center;
  color: #fff;
  border: 1px solid;
  border-color: #fff;
  background-color: #cc1922;
  border-radius: 8px;
  position: absolute;
  top:4px;
  right: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.text-list{
  list-style-type: none;
  padding: 0;
  margin: 0;
}


.card-footer{
  text-align: center;
}

.prio-high{
  background-color: red;
}

.text-row{
  display: grid;
  grid-template-columns: 65px auto;
}

.timestamp{
  letter-spacing: -1px;
  font-weight: 500;
  width: 70px;
  grid-column: 1;
}

.transcribed_text{
  letter-spacing: 0px;
  grid-column: 2;
}

