html, body{
    height: 100%;
    margin: 0;
    overflow-y: hidden;
}

body::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/src/assets/images/lmdh.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: -2;
}

body::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0.5) 25%);
    z-index: -1;
}

body.background.dark-mode::after{
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5) 25%);
}

.grid-container{
    display: grid;
    gap: 0px;
    grid-template-columns: 150px auto 150px;
    grid-template-rows: auto;
    grid-template-areas: 
        "menu wordmark login"
        "main main main"
        "main main main";
}

.content{
    grid-area: main;
    color: var(--text-color, black);
}

.background{
    background-color: var(--background-color, #ffffff);
    color: var(--text-color, #747474);
}

.text-input{
    border: 2px solid var(--input-border-color, #ffffff);
    background-color: var(--input-background-color, #c2c2c2);
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
}

.wordmark{
    padding: 30px;
    grid-area: wordmark;
    place-self: center;
}

.menu{
    grid-area: menu;
    place-self: center;
}

.darkmode{
    grid-area: login;
    place-self: center;
}

.gradient{
    z-index: 1;
}