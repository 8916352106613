.light-mode,
.dark-mode {
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100vh;
}

.pageLayout {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 2rem;
}

.removeLinkStyle {
  text-decoration: none;
  outline: none;
}

.divider {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.contentWrapperSmall {
  margin-top: 1rem;
}

.contentWrapperBig {
  margin-top: 2rem;
}